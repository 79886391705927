/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import {graphql, useStaticQuery} from 'gatsby'
import Image from 'gatsby-image'
import React from 'react'

import {rhythm} from '../utils/typography'

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            github
            email
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata
  return (
    <div
      style={{
        display: `flex`,
        marginBottom: rhythm(0.75),
      }}
    >
      <Image
        fixed={data.avatar.childImageSharp.fixed}
        alt={author.name}
        style={{
          marginRight: rhythm(1 / 2),
          marginBottom: 0,
          minWidth: 50,
          borderRadius: `100%`,
        }}
        imgStyle={{
          borderRadius: `50%`,
        }}
      />
      <p>
        Hi 
        <span role="img" aria-label="hi">👋</span>! I am a final year Advanced Computer Science MSc student at{' '}
        <span role='img' aria-label='graduation'>
          🎓
        </span>
        University of Oxford. This is a place where I (will hopefully) write
        about topics I find interesting. I plan to write about my personal
        projects and learning experiences, as well as some random tech topics.{' '}
        <br />
        <span>
          <a href={`https://github.com/${social.github}`} target='blank'>
            {' '}
            <span role='img' aria-label='laptop'>
              💻
            </span>
            github
          </a>
          {' | '}
          <a href={`mailto:${social.email}`}>
            <span role='img' aria-label='laptop'>
              ✉️
            </span>
            contact me
          </a>

          {' | '}
          <a href='/my-reading-list'>
            <span role='img' aria-label='books'>
              📚
            </span>
            my reading list
          </a>
          
          {' | '}
          <a href='/projects'>
            <span role='img' aria-label='projects'>
              👨‍💻
            </span>
            my projects
          </a> 
        </span>
      </p>
    </div>
  )
}

export default Bio
